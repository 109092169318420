import React from 'react';

import { EMOJI } from '../../../shared/enums';

const ContentBlock = () => (
  <section className='content-block'>
    <div className='container'>
      <div className='content-block__intro'>
        <h2 className='content-block__intro-title'>100% Mila-backed guarantee.</h2>
        <figure className='content-block__image'>
          {EMOJI.HANDSHAKE}
        </figure>
        <div className='content-block__intro-description'>
          <p>
            At Mila we feel incredibly lucky to be able to make products
            that have a meaningful impact on the health
            and wellbeing of the people we love. We take that responsibility seriously.
          </p>
        </div>
      </div>
      <div className='content-block__main'>
        <div className='content-block__description'>
          <p>That's why we provide our Mila-backed guarantee, including:</p>
        </div>
        <div className='content-block__card-wrap'>
          <div className='content-block__card'>
            <h3 className='content-block__card-title'>30-Day Refunds</h3>
            <p className='content-block__card-description'>
              If you don’t love Mila, you can return her within 30 days for
              a full refund, minus shipping and handling.
            </p>
          </div>
          <div className='content-block__card'>
            <h3 className='content-block__card-title'>One-Year Warranty</h3>
            <p className='content-block__card-description'>
              Should something happen with your Mila, no worries—you’re
              covered.
            </p>
          </div>
          <div className='content-block__card'>
            <h3 className='content-block__card-title'>The Mila Promise</h3>
            <p className='content-block__card-description'>Honestly clean air from an honest company. </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ContentBlock;
